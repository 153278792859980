* {
  margin:0;
  padding:0;
  -ms-overflow-style: none;
}

.procedure-body {
  min-height: 35px;
  flex:  1;
}

::-webkit-scrollbar {
    display: none;
}

.procedure-body-nav-wrapper {
  display: flex;
  width: 100%;
  flex: 1 1 auto;
  overflow-y: scroll;
}

.procedure-body-nav-wrapper > div {
  padding-left: 0px;
  padding-right: 0px;
}

.procedure-body-nav-wrapper .procedure-body-nav {
  flex: 0 0 20px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.procedure-body-nav-wrapper .procedure-body-nav.hidden {
  visibility: hidden;
}

.procedure-body-nav-wrapper .procedure-body-nav:hover {
  background-color: #EEE;
}

.procedure-body-nav-wrapper .arrow-left {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right:10px solid #CCC;
}

.procedure-body-nav-wrapper .arrow-right {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #CCC;
}

.procedure-body-nav-wrapper .procedure-body {
  margin-left: 0px;
  margin-right: 0px;
  flex: 1 1 auto;
}

.procedure-body ul,
.procedure-body ol{
  margin-left: 20px;
}
.procedure-body li{
  margin: 5px;
}

.standard-size .procedure-body img{
  max-width: 315px;
  max-width: min(315px, 100%);
  height: auto;
}
.procedure-body.document-view img{
  text-align: center;
}

.procedure-body.document-view figure.image{
  text-align: center;
}
.full-size .procedure-body img{
  max-width: 100%;
  max-height: 600px;
  height: auto;
  width: auto;
}

.procedure-body li img{
  max-width: 285px;
  height: auto;
}

.procedure-body pre{
  white-space: pre-wrap;
}

.standard-size .procedure-body iframe[src*="www.youtube.com"],
.standard-size .procedure-body iframe[src*="vimeo.com"]{
  max-width: 315px;
  max-width: min(315px, 100%);
  max-height: 200px;
  height: auto;
}

.full-size .procedure-body iframe[src*="www.youtube.com"],
.full-size .procedure-body iframe[src*="vimeo.com"]{
  height: auto;
  width: 100%;
  aspect-ratio: 16 / 9;
}

.procedure-body.video-procedure {
  flex: 1;
  position: relative;
  margin: -16px;
}


.procedure-body.video-procedure iframe[src*="www.youtube.com"],
.procedure-body.video-procedure iframe[src*="vimeo.com"]{
  top: 0;
  max-width: none;
  max-height: none;
  width: 100%;
  height: 100%;
  position: absolute;
}


.standard-size .procedure-body figure.image {
  margin: 2px 0 2px 2px;
  background: none;
  border: none;
  max-width: 315px;
  max-width: min(315px, 100%);
}

.full-size .procedure-body figure.image {
  max-width: 100%;
}

.standard-size .procedure-body figure.image img {
  margin: 0;
  max-width: 314px;
}


.full-size .procedure-body figure.image img,
.full-size .procedure-body figure.image img{
  max-width: 100%;
  max-height: 600px;
  height: auto;
  width: auto;
}

.procedure-body.empty:before{
  width: 100%;
  height: 100px;
  margin-bottom: -100px;
  display: block;
  content: attr(placeholder);
  color: #bababa;
  cursor: pointer;
  border: none;
}

.viewport{
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #f0f0f0;
  width: 100vw;
  overflow-x: hidden;
  position: relative;
}

.viewport-inner{
  display: flex;
  flex: 1;
  flex-direction: row;
}

.like.active{
  color: #106eb1;
  text-shadow: 1px 1px 5px #42d9e8;
}

.dislike.active{
  color: #902222;
  text-shadow: 1px 1px 5px #ff3b00;
}

.media-picker {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
}
.media-picker .media-thumbnail{
  display: inline-block;
  margin: 5px 5px;
  padding-top: 75px;
  min-width: 30px;
  cursor: pointer;
  vertical-align: bottom;
  background-repeat: no-repeat !important;
  background-size: 70px auto !important;
  background-position: center top !important;
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.media-picker .media-thumbnail[src*='document_type=file-image'] {
  background-image: url('images/file-image.png') !important;
}
.media-picker .media-thumbnail[src*='document_type=file-pdf'] {
  background-image: url('images/file-pdf.png') !important;
}
.media-picker .media-thumbnail[src*='document_type=file-word'] {
  background-image: url('images/file-word.png') !important;
}
.media-picker .media-thumbnail[src*='document_type=file-excel'] {
  background-image: url('images/file-excel.png') !important;
}
.media-picker .media-thumbnail[src*='document_type=file-powerpoint'] {
  background-image: url('images/file-powerpoint.png') !important;
}
.media-picker .media-thumbnail[src*='document_type=file-illustrator'] {
  background-image: url('images/file-illustrator.png') !important;
}
.media-picker .media-thumbnail[src*='document_type=file-photoshop'] {
  background-image: url('images/file-photoshop.png') !important;
}
.media-picker .media-thumbnail[src*='document_type=file-indesign'] {
  background-image: url('images/file-indesign.png') !important;
}
.media-picker .media-thumbnail[src*='document_type=file-empty'] {
  background-image: url('images/file-empty.png') !important;
}

.media-picker > div{
  flex: 1 1 300px;
  min-width: 240px;
  max-width: 350px;
  float: left;
  height: 120px;
  overflow: hidden;
  text-align: center;
  border: 1px solid #e9e9e9;
  margin: 0 -1px -1px 0;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  box-shadow: 1px 1px 6px -3px rgba(0, 0, 0, 0.3);
  background: #fcfcfc;
}

.media-picker > div:hover{
  background: #333;
  color: white;
  cursor: pointer;
}
.media-picker > div img{
  background: black;
  margin: -75px auto 5px auto;
  max-height: 75px;
  max-width: 100px;
  display: block;
}

input.hidden{
  display: none;
}

a[href*='document_type=file-image'] {
  background-image: url('images/file-image.png') !important;
}
a[href*='document_type=file-pdf'] {
  background-image: url('images/file-pdf.png') !important;
}
a[href*='document_type=file-word'] {
  background-image: url('images/file-word.png') !important;
}
a[href*='document_type=file-excel'] {
  background-image: url('images/file-excel.png') !important;
}
a[href*='document_type=file-powerpoint'] {
  background-image: url('images/file-powerpoint.png') !important;
}
a[href*='document_type=file-illustrator'] {
  background-image: url('images/file-illustrator.png') !important;
}
a[href*='document_type=file-photoshop'] {
  background-image: url('images/file-photoshop.png') !important;
}
a[href*='document_type=file-indesign'] {
  background-image: url('images/file-indesign.png') !important;
}
a[href*='document_type=file-empty'] {
  background-image: url('images/file-empty.png') !important;
}

a[href*='/images/file-generic'],
img[src*='/images/file-generic']{
  display: block;
  margin: 5px 5px;
}

a[href*='document_type=file']{
    display: inline-block;
    margin: 5px 5px;
    padding-top: 55px;
    min-width: 30px;
    cursor: pointer;
    vertical-align: bottom;
    background-repeat: no-repeat !important;
    background-size: 50px auto !important;
    background-position: center top !important;
    max-width: 300px;
}

a.no-thumb{
  background-image: none !important;
  background: none;
  display: inline;
  margin: 0;
  padding: 0;
  max-width: auto;
}

.mce-tinymce {
  height: auto !important;
  width: auto !important;
}

.mce-tinymce-inline .mce-stack-layout-item .mce-flow-layout {
  white-space: normal;
}

.mce-colorbutton-grid tr:nth-child(6) td {
  padding-top: 16px;
}

/* TinyMCE scrolling body only. Breaks floating toolbars */
/*

.procedure-body,
.mce-container.mce-tinymce,
.mce-container-body.mce-stack-layout{
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.mce-toolbar-grp{
  flex: 0 0;
}

.mce-edit-area{
  flex: 1 1;
  overflow-y: scroll;
}

.mce-edit-focus{
  overflow-y: scroll;
}
*/
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

.Error {
  color: red;
  font-size: large;
}

a{
  color: #337ab7;
  text-decoration: none;
}

a:hover{
  text-decoration: underline;
}

.main-menu a:hover {
  text-decoration: none;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.procedure-card {
  direction: ltr;
  box-sizing: border-box;
}

.general-card .step-image{
  max-width: 50px;
  max-height: 50px;
  width: auto;
  height: auto;
  margin-right: 10px;
}

.procedure-card-slide-wrapper .procedure-card {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.procedure-card-slide-wrapper.left .procedure-card *, .procedure-card-slide-wrapper.right .procedure-card * {
  pointer-events: none;
}

.procedure-card-slide-wrapper {
  transition: transform 400ms linear, max-width 400ms linear, left 400ms linear, right 400ms, flex 400ms, z-index 400ms;
}

.procedure-card-slide-wrapper.hidden {
  display: none;
}

.procedure-card-slide-wrapper.active {
  max-width: 350px;
  z-index: 10000;
  margin-left: 10px;
  margin-right: 10px;
}

.procedure-card-slide-wrapper.fullscreen {
  transition: transform 400ms linear, max-width 400ms linear, left 400ms linear, right 400ms, flex 400ms;
}

.procedure-card-slide-wrapper.active.fullscreen {
  max-width: calc(100vw - 700px) !important;
  min-width: 350px !important;
}

.procedure-card-slide-wrapper.fullscreen .procedure-card{
  width: calc(100vw - 700px) !important;
  max-width: calc(100vw - 700px) !important;
  min-width: 350px !important;
}

.procedure-card-slide-wrapper.autoleft {
  margin-left: auto;
}

.procedure-card-slide-wrapper.autoright {
  margin-right: auto;
}

.procedure-card-slide-wrapper.moving-left {
  direction: rtl;
}

.procedure-card-slide-wrapper.moving-right {
  direction: ltr;
}

.procedure-card-slide-wrapper.left {
  user-select: none;
  max-width: 30px;
  overflow-x: visible;
  transform-origin: left;
}

.procedure-card-slide-wrapper.right {
  user-select: none;
  max-width: 30px;
  overflow-x: visible;
  direction: rtl;
  transform-origin: right;
}

.procedure-card-slide-wrapper.left.stack-1 {
  transform: rotateY(30deg) scale(0.95,0.95);
  z-index: 8;
}
.procedure-card-slide-wrapper.left.stack-2 {
  transform: rotateY(30deg) scale(0.90,0.90);
  z-index: 7;
}
.procedure-card-slide-wrapper.left.stack-3 {
  transform: rotateY(30deg) scale(0.85,0.85);
  z-index: 6;
}
.procedure-card-slide-wrapper.left.stack-4 {
  transform: rotateY(30deg) scale(0.80,0.80);
  z-index: 5;
}

.procedure-card-slide-wrapper.right.stack-1 {
  transform: rotateY(-30deg) scale(0.95,0.95);
  z-index: 4;
}
.procedure-card-slide-wrapper.right.stack-2 {
  transform: rotateY(-30deg) scale(0.90,0.90);
  z-index: 3;
}
.procedure-card-slide-wrapper.right.stack-3 {
  transform: rotateY(-30deg) scale(0.85,0.85);
  z-index: 2;
}
.procedure-card-slide-wrapper.right.stack-4 {
  transform: rotateY(-30deg) scale(0.80,0.80);
  z-index: 1;
}

.fa-button {
  color: #646464;
}

.procedure-details-table {
  width: 100%;
}

.procedure-details-table td {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-bottom: solid 1px #ddd;
}

.procedure-details-table tr:last-child td {
  border-bottom: none;
}

.procedure-details-table tr td:nth-child(1) {
  font-weight: bold;
  padding-right: 0.5em;
  white-space: nowrap;
}

.procedure-details-table tr td:nth-child(2) {
  width: 99%;
}

/* <Jira Exportable Tag> */
.step-edit-content .jira-exportable {
  border: solid 1px lightgray;
  border-radius: 5px;
  padding: 4px;
}

.step-edit-content .jira-exportable::before {
  content: 'Jira Exportable';
  display: block;
  font-family: monospace;
  color: #555;
  background-color: lightgray;
  font-size: 0.8em;
  padding: 2px;
  padding-left: 6px;
  margin: -4px;
  margin-bottom: 2px;
}
.syndicated-view-stats-tr:hover{
  opacity: 0.7;
  cursor: pointer;
}
.syndicated-table-extension{
  color:#7F7F7F
}
.addorned-text-field[data-shrink="true"]{
  opacity: 0;
}
.addorned-text-field{
  opacity: 1;
}
/* </Jira Exportable Tag> */