#root, #main, .App, .AuthenticationWrapper{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.App, .AuthenticationWrapper{
  height: 100vh;
  overflow-y: hidden;
}

.landing {
  font-size: 11px;
}

  .landing > div {
    margin-top: -1px
  }

  .landing a{
    color: #2C0571;
  }

  .landing a:hover{
    opacity: 0.8;
  }

  .landing .header{
    height: 7vh;
    min-height: 42px;
  }

    .landing .header a{
      margin-left: 2.5em;
      font-size: 1.1em;
    }

    .landing .header .logo{
      height: 70%;
      object-fit: contain;
      flex-shrink: 0; /* Fix for IE */
    }

    .landing .header .links{
      flex: 1;
      text-align: right;
    }

  .landing .background {
    background: radial-gradient(circle at top, #367DD9, #1453A4);
    position: relative;
    overflow: hidden;
  }

  .landing .banner{
    height: 38vh;
    min-height: 228px;
    position: relative;
    overflow: hidden;
  }

      @media(max-width: 80vh) {
        .landing .banner:before{
          background-size: cover;
        }
      }

    .landing .banner .centered-content {
      justify-content: space-between;
      flex-wrap: wrap-reverse;
    }

    .landing .banner .video-area {
      width: 60vh;
      max-width: 55%;
      min-width: 368px;
    }
    .landing .banner .video-container {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%; /* 16:9 ratio */
    }
    .landing .banner .video-container iframe{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 100;
      border: none;
    }

    .landing .features-wrapper {
      overflow: hidden;
    }

    .landing .features {
      color: white;
    }

    .landing .features .slider {
      display: flex;
      transition: left 500ms;
      position: relative;
      left: 0;
    }

    .landing .features .feature{
      display: flex;
      justify-content: space-between;
    }

    .landing .features .feature .left {
      flex: 1 0 67%;
    }
    .landing .features .feature .right {
      flex: 1 0 33%;
    }

    .landing .features .feature p {
      font-weight: 100;
      font-size: 1em;
    }

    .landing .features .feature h1 {
      margin-bottom: 0;
      font-size: 2.2em;
    }

    .landing .features .selectors {
      display: flex;
      justify-content: center;
      margin-top: 0.5em;
      margin-bottom: 0.5em;
      width: 90%;
    }

    .landing .features .selectors div {
      width: 0.6em;
      height: 0.6em;
      border-radius: 50%;
      margin: 0.2em;
      background-color: #1453A4;
      transition: background-color 500ms;
      cursor: pointer;
      z-index: 100;
    }

    .landing .features .selectors .active {
      background-color: #FFF;
    }

    .landing .banner .login {
      width: 33%;
      box-sizing: border-box;
      padding: 1em;
      background: rgba(255,255,255,0.8);
      height: 90%;
      border-radius: 1%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      z-index: 100;
    }

    .embedded_login {
      height: 100%;
      background: WhiteSmoke;
    }

    .embedded_login .login {
      margin: 10px;
      box-sizing: border-box;
      padding: 1em;
      background: rgba(255,255,255,1.0);
      height: 60%;
      border-radius: 1%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      z-index: 100;
    }

      .landing .banner .login h2{
        color: #2C0571;
        font-size: 1.8em;
      }

      .landing .banner .login a{
        font-size: 0.9em;
        margin-top: 1em;
      }

      .landing .banner .login .inputs > div{
        font-size: 1.15em !important;
        height: 4em !important;
      }

        .landing .banner .login .inputs > div > input{
          margin-top: 0.7em !important;
        }

        .landing .banner .login .inputs > div > div{
          bottom: 0.75em !important;
        }

        .landing .banner .login .inputs > div > label{
          top: 2em !important;
        }

        .landing .banner .login .inputs > div > div > hr:first-of-type{
          border-bottom: 1px solid rgba(0,0,0,0.3) !important;
        }

      .landing .banner .login .actions {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

        .landing .actions button{
          background-color: #F48120 !important;
          height: 3.2em !important;
          line-height: 3.2em !important;
        }

          .landing .actions button span{
            font-weight: 100 !important;
            font-size: 1.3em !important;
          }

          .landing .actions button > div > div {
            height: 3.2em !important;
          }

        .landing .actions div{
          box-shadow: none !important;
        }

      @media(max-width: 120vh) {
        .landing .banner .video-area {
          min-width: initial;
        }
        .landing .banner .login {
          width: 42.5%;
        }

        .landing .features .feature .left {
          flex: 1 0 57.5%;
        }
        .landing .features .feature .right {
          flex: 1 0 42.5%;
        }
      }

      @media(max-width: 90vh), (max-width: 600px) {
        .landing .banner .video-area {
          width: 100%;
          max-width: initial;
          min-width: initial;
          margin-bottom: 2em;
        }

        .landing .banner .login {
          width: 100%;
          margin: 1em 0 2em 0;
        }

        .landing .banner {
          height: auto;
        }

        .landing .features .feature {
          flex-wrap: wrap;
        }

        .landing .features .feature .left {
          flex: 1 1 100%;
        }
        .landing .features .feature .right {
          flex: 1 1 100%;
        }
      }

  .landing .highlights {
    min-height: 42vh;
    display: flex;
  }

    @media(max-height: 600px) {
      .landing .highlights {
        min-height: 252px;
      }
    }

    .landing .highlights .centered-content {
      padding: 1em 0;
      flex-wrap: wrap;
      max-width: calc(130vh + 2em);
      align-items: flex-start;
    }

    .landing .highlights .highlight {
      flex: 1;
      box-sizing: border-box;
      padding: 1em;
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 240px;
      max-width: 50%;
    }

      @media(max-width: 60vh) {
        .landing .highlights .highlight {
          max-width: none;
          min-width: 100%
        }
      }

    .landing .highlights .highlight img {
      max-height: 18vh;
      min-height: 132px;
    }

    .landing .highlights .highlight h3 {
      margin: 1em 0;
      font-weight: 100;
      color: #00AB6C;
      font-size: 1.7em;
      text-align: center;
    }

    .landing .highlights .highlight p {
      text-align: center;
      font-size: 1em;
      font-weight: 100;
      line-height: 1.5em;
      color: rgba(0,0,0,0.67);
    }

    .landing .highlights .highlight strong {
      font-weight: 500;
      color: #00AB6C;
    }

  .landing .site-actions .actions {
    width: calc(33% - 2em);
    margin-bottom: 2em
  }

    @media(max-width: 70vh) {
      .landing .site-actions .actions {
        width: calc(100%);
        margin-bottom: 2em
      }
    }

  .landing .custom-content {
    padding: 9vh 0;
    box-sizing: border-box;
  }

  .landing .custom-content .centered-content {
    display: block;
  }

  .landing .custom-content .centered-content img {
    width: 100%;
    height: auto;
  }

  .landing .custom-content .centered-content > aside {
    margin-bottom: 3em;
    font-weight: 100;
    font-size: 1.1em;
    line-height: 1.4em;
    border-left: 1px solid;
    padding-left: 2em;
  }

  .landing .custom-content h1 {
    margin-bottom: 1.2em;
    font-size: 2em;
  }

  .landing .about {
    margin-top: 7vh;
    padding: 9vh 0;
    box-sizing: border-box;
    background: #00AB6C;
    color: #FFF;
  }

    .landing .about .centered-content {
      display: block;
    }

    .landing .about h1 {
      margin-bottom: 1.2em;
      font-size: 2em;
    }

    .landing .about h2 {
       margin: 0.5em 0;
       font-size: 1.2em;
     }

    .landing .about .centered-content > aside {
      margin-bottom: 3em;
      font-weight: 100;
      font-size: 1.1em;
      line-height: 1.4em;
      border-left: 1px solid #FFF;
      padding-left: 2em;
    }

    .landing .about ul {
      list-style: none;
    }

    .landing .about li {
      margin-bottom: 2em;
      display: flex;
      align-items: center;
    }

      .landing .about li img {
        border-radius: 50%;
        width: 8em;
        height: 8em;
        border: 0.6em solid #EEE;
      }

      .landing .about li div {
        margin-left: 2em;
        max-width: 50em;
        padding-bottom: 1em;
      }

      .landing .about li aside {
        margin-bottom: 0.5em;
        font-weight: normal;
        font-size: 1.1em;
      }

      .landing .about li p {
        font-weight: 100;
      }

  .landing .footer {
    padding: 7vh 0;
    box-sizing: border-box;
    background: #333;
    color: #FFF;
  }

    .landing .footer .centered-content {
      display: block;
    }

    .landing .footer h1 {
      font-size: 1.8em;
      margin-bottom: 1em;
    }

    .landing .footer p {
      font-weight: 100;
    }

    .landing .footer #contact {
      display: flex;
    }

      .landing .footer #contact div {
        flex: 1;
      }

      .landing .footer #contact .contact-form {
        max-width: 50vh;
        width: 50%;
        padding-left: 2vh;
      }

    .landing .footer input,
    .landing .footer a {
      border: none;
      display: block;
      width: 100%;
      font-size: 1em;
      margin-bottom: 1px;
      padding: 0.5em;
      box-sizing: border-box;
    }
    .landing .footer textarea {
      border: none;
      width: 100%;
      height: 5em;
      font-size: 1em;
      margin-bottom: 1px;
      padding: 0.5em;
      box-sizing: border-box;
    }

    .landing .footer input[type=submit],
    .landing .footer a {
      background: #F48120;
      color: #FFF;
      font-weight: 100;
      border-radius: 2px;
      cursor: pointer;
      height: 2.5em;
    }

    .landing .footer input[type=submit]:hover,
    .landing .footer a:hover {
      background: #F59749;
    }

    .landing .footer .legal {
      font-weight: 100;
      font-size: 0.9em;
      opacity: 0.7;
      border-top: 1px solid rgba(255,255,255,0.5);
      padding-top: 3vh;
      margin-top: 3vh;
    }

  .landing .centered-content {
    margin: 0 auto;
    max-width: 130vh;
    padding: 0 20px;
    width: 90%; /* Fixes rendering in IE */
    height: 100%;
    display: flex;
    align-items: center;
  }

  /* IE doesn't wrap the text without this */
  .highlight p {
    width: 100%;
  }

  @media(max-height: 600px) {
    .landing .centered-content {
      max-width: 780px
    }

    .landing .highlights .centered-content {
      max-width: 804px;
    }
  }

  @media(min-height: 660px) {
    .landing {
      font-size: 12px;
    }
  }

  @media(min-height: 720px) {
    .landing {
      font-size: 13px;
    }
  }

  @media(min-height: 780px) {
    .landing {
      font-size: 14px;
    }
  }

  @media(min-height: 840px) {
    .landing {
      font-size: 15px;
    }
  }

  @media(min-height: 960px) {
    .landing {
      font-size: 16px;
    }
    .landing .centered-content {
      padding: 0 30px;
    }
  }

  @media(min-height: 1080px) {
    .landing {
      font-size: 18px;
    }
  }

  @media(min-height: 1200px) {
    .landing {
      font-size: 20px;
    }
    .landing .centered-content {
      padding: 0 60px;
    }
  }

  @media(min-height: 1320px) {
    .landing {
      font-size: 22px;
    }
  }

  @media(min-height: 1440px) {
    .landing {
      font-size: 22px;
    }
  }

